import Stars from "./Stars";

const Rating = () => {
  return (
    <div className="mb-6 flex flex-col gap-2 tablet:mb-8 desktop:mb-12">
      <div className="text-textBodySemi text-label">
        Рейтинг KUBER (код 333) на{" "}
        <a
          href="https://ati.su/firms/333/rating"
          target="_blank"
          className="self-stretch text-textBodySemi text-primary underline underline-offset-[3px]"
        >
          ATI.SU
        </a>
      </div>
      <div className="flex items-center gap-4">
        <Stars />
        <div className="text-textSmallSemi">5.0 баллов</div>
      </div>
    </div>
  );
};

export default Rating;
