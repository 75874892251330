import React from "react";
import { LandingEntity } from "@/domain";

interface Props {
  review: LandingEntity;
}

const Review: React.FC<Props> = ({ review }) => {
  return (
    <>
      <div className="flex min-h-[234px] flex-col gap-4 rounded-xl border border-divider p-6 tablet:min-h-[278px] tablet:rounded-[40px] tablet:p-8 desktop:min-h-fit desktop:gap-5 desktop:p-10">
        <div className="flex flex-col tablet:gap-1 desktop:gap-2">
          <h5 className="text-smallTitle desktop:text-title1">{review.title}</h5>
          <span className="text-textBodySemi text-label desktop:text-textMediumSemi">{review.subtitle}</span>
        </div>
        <p className="text-textBody">{review.text}</p>
      </div>
    </>
  );
};

export default React.memo(Review);
