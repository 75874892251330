import React, { useCallback, useState } from "react";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import "swiper/css";

import Review from "./Review";
import { LandingEntity } from "@/domain";
import Rating from "./Rating";
import Section from "@/components/Section2";
import IconButton from "@/components/Button2/IconButton";
import Arrow from "@/components/icons/IphoneArrow";
import { breakpoints } from "@/styles/constants";

interface Props {
  reviews: LandingEntity[];
}

interface HeaderProps {
  prevButtonDisabled: boolean;
  nextButtonDisabled: boolean;
}

const Header: React.FC<HeaderProps> = ({ prevButtonDisabled, nextButtonDisabled }) => {
  const swiper = useSwiper();
  const goToNextSlide = useCallback(() => {
    swiper.slideNext();
  }, [swiper]);
  const goToPrevSlide = useCallback(() => {
    swiper.slidePrev();
  }, [swiper]);

  return (
    <div className="flex items-center justify-between tablet:items-start">
      <h2 className="text-header3 desktop:text-header2">Отзывы</h2>
      <div className="flex gap-2">
        <IconButton
          onClick={goToPrevSlide}
          variant="outline"
          disabled={prevButtonDisabled}
          icon={
            <div className="rotate-180">
              <Arrow />
            </div>
          }
        ></IconButton>
        <IconButton
          onClick={goToNextSlide}
          disabled={nextButtonDisabled}
          variant="outline"
          icon={<Arrow />}
        ></IconButton>
      </div>
    </div>
  );
};

const Reviews: React.FC<Props> = ({ reviews }) => {
  const [isPrevButtonDisabled, setPrevButtonDisabled] = useState(true);
  const [isNextButtonDisabled, setNextButtonDisabled] = useState(false);

  return (
    <Section data-testid="reviews-section">
      <div className="mt-10 w-full max-w-xxl py-10 tablet:mt-0 tablet:py-12 desktop:py-15">
        <Swiper
          breakpoints={{
            [breakpoints.MOBILE]: {
              slidesPerView: 1,
            },
            [breakpoints.TABLET]: {
              slidesPerView: 2,
            },
          }}
          spaceBetween={24}
          navigation={true}
          modules={[Navigation]}
          onSlideChange={(swiper) => {
            setPrevButtonDisabled(swiper.isBeginning);
            setNextButtonDisabled(swiper.isEnd);
          }}
        >
          <div slot="container-start" className="flex flex-col gap-5 tablet:gap-2 desktop:gap-5">
            <Header prevButtonDisabled={isPrevButtonDisabled} nextButtonDisabled={isNextButtonDisabled} />
            <Rating />
          </div>
          <div className="flex flex-nowrap gap-6 ">
            {reviews.map((review, index) => (
              <SwiperSlide key={review.id}>
                <Review review={review} />
              </SwiperSlide>
            ))}
          </div>
        </Swiper>
      </div>
    </Section>
  );
};

export default React.memo(Reviews);
