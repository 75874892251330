import React, { useCallback } from "react";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import "swiper/css";

import { LandingEntity } from "@/domain";
import Section from "@/components/Section2";
import LandingSectionTitle from "src/landing/components/SectionTitle";
import IconButton from "@/components/Button2/IconButton";
import Arrow from "@/components/icons/IphoneArrow";

interface Props {
  scheme: LandingEntity[];
}

const Slide: React.FC<{ data: LandingEntity }> = ({ data }) => {
  const swiper = useSwiper();
  const goToNextSlide = useCallback(() => {
    swiper.slideNext();
  }, [swiper]);
  const goToPrevSlide = useCallback(() => {
    swiper.slidePrev();
  }, [swiper]);

  // TODO: temporary kostyl
  const imgUrl =
    data.title === "Первая миля"
      ? "/images/how-it-works/first_mile.png"
      : data.title === "Последняя миля"
      ? "/images/how-it-works/last_mile.png"
      : data.title === "Порожняя миля"
      ? "/images/how-it-works/empty_mile.png"
      : undefined;

  // at768:flex-row-reverse
  return (
    <div className="flex w-full flex-col gap-6 tablet:gap-8 desktop:flex-row desktop:gap-8">
      <div className="flex basis-2/5 flex-col-reverse items-start justify-between gap-8 tablet:flex-col">
        <div className="flex w-full flex-col gap-4 tablet:gap-5 desktop:gap-8">
          <div className="flex w-full flex-row items-center justify-between desktop:pt-4">
            <LandingSectionTitle>{data.title}</LandingSectionTitle>
            <div className="hidden gap-2 tablet:flex desktop:hidden">
              <IconButton
                variant="outline"
                icon={
                  <div className="rotate-180">
                    <Arrow />
                  </div>
                }
                onClick={goToPrevSlide}
              />
              <IconButton variant="outline" icon={<Arrow />} onClick={goToNextSlide} />
            </div>
          </div>
          <p className="text-textBody text-secondaryText tablet:text-textMedium">{data.text}</p>
        </div>
        <div className="flex gap-2 tablet:hidden desktop:flex">
          <IconButton
            variant="outline"
            icon={
              <div className="rotate-180">
                <Arrow />
              </div>
            }
            onClick={goToPrevSlide}
          />
          <IconButton variant="outline" icon={<Arrow />} onClick={goToNextSlide} />
        </div>
      </div>
      <div className="basis-3/5">
        {data.image && (
          <img
            src={imgUrl ?? data.image.link}
            alt={data.image.name}
            className="w-full rounded-2xl object-cover tablet:h-[334px] desktop:h-[355px]"
          />
        )}
      </div>
    </div>
  );
};

const HowItWorks: React.FC<Props> = ({ scheme }) => {
  return (
    <Section data-testid="howitworks-section">
      <div className="w-full max-w-xxl px-0 pb-8 pt-10 tablet:mb-12 tablet:rounded-[40px] tablet:border tablet:border-divider tablet:p-8 tablet:pt-10 desktop:my-15 desktop:p-10">
        <Swiper spaceBetween={20} loop>
          {scheme.map((item, idx) => (
            <SwiperSlide key={item.id}>
              <Slide data={item} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </Section>
  );
};

export default React.memo(HowItWorks);
