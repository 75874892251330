import React from "react";
import NextLink from "next/link";
import News from "@/components/News";
import Arrow from "@/components/icons/Arrow";
import { LandingNewsEntity } from "@/domain";
import Section from "@/components/Section2";

interface Props {
  news: LandingNewsEntity[];
}

const Media: React.FC<Props> = ({ news = [] }) => {
  if (news.length === 0) return null;

  return (
    <Section data-testid="media-section">
      <div className="flex flex-col gap-7 tablet:gap-6 desktop:max-w-xxl desktop:gap-8">
        <div className="flex w-full flex-col items-baseline justify-between gap-2 tablet:flex-row">
          <span className="text-smallTitle tablet:text-header3 desktop:text-header2">Что нового в KUBER?</span>
          <NextLink href="/news" passHref>
            <div className="flex cursor-pointer items-center space-x-2 text-textBodySemi text-primary hover:text-hover active:text-active">
              <span>Все новости</span>
              <span className="flex h-6 w-6">
                <Arrow />
              </span>
            </div>
          </NextLink>
        </div>
        <div className="no-scrollbar hidden gap-8 overflow-x-scroll tablet:flex tablet:flex-row ">
          {news.slice(0, 3).map((entry, idx) => (
            <News key={idx} data={entry} brief />
          ))}
        </div>
        <div className="no-scrollbar flex flex-col gap-8 overflow-y-scroll tablet:hidden">
          {news.slice(0, 2).map((entry, idx) => (
            <News key={idx} data={entry} brief />
          ))}
        </div>
      </div>
    </Section>
  );
};

export default React.memo(Media);
