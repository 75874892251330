import React, { useCallback, useState } from "react";

import FaqItem from "./FaqItem";
import { LandingEntity } from "@/domain";
import LandingSectionTitle from "src/landing/components/SectionTitle";
import Section from "@/components/Section2";
import Micro from "@/components/icons/Micro";

interface Props {
  data: LandingEntity[];
}

const Faq: React.FC<Props> = ({ data }) => {
  const [expandedItemId, setExpandedItemId] = useState<number | undefined>(data[0].id);
  const handleToggle: (id: number) => void = useCallback(
    (id) => {
      const newId = expandedItemId === id ? undefined : id;
      setExpandedItemId(() => newId);
    },
    [expandedItemId],
  );

  return (
    <Section data-testid="faq-section">
      <div className="flex w-full max-w-xxl flex-row gap-8 pb-20 pt-10 tablet:pb-24 tablet:pt-[46px] desktop:pb-28 desktop:pt-15">
        <div className="hidden max-w-[290px] px-9 desktop:block">
          <div className="flex flex-col gap-6">
            <Micro />
            <LandingSectionTitle>
              Отвечаем <span className="whitespace-nowrap"> на ваши</span> вопросы
            </LandingSectionTitle>
          </div>
        </div>
        <div className="w-full">
          {data.map((item) => (
            <FaqItem key={item.id} data={item} open={item.id === expandedItemId} onToggle={handleToggle} />
          ))}
        </div>
      </div>
    </Section>
  );
};

export default React.memo(Faq);
