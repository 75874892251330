const NoteIcon = () => (
  <svg width="137" height="136" viewBox="0 0 137 136" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="24.75" y="24" width="88" height="88" rx="44" fill="#2196F3" />
    <path d="M63.166 49.3335V54.6668" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M76.4993 76.0003L84.4993 84.0003M63.166 62.667L72.5927 85.2937L75.9393 75.4403L85.7927 72.0937L63.166 62.667Z"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M53.7393 53.2402L57.5126 57.0136"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M49.833 62.667H55.1663" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M53.7393 72.0936L57.5126 68.3203"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M68.8193 57.0136L72.5927 53.2402"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default NoteIcon;
