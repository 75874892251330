const Micro = () => (
  <svg
    width="89"
    height="88"
    viewBox="0 0 89 88"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="stroke-primary"
  >
    <rect x="2" y="1.5" width="85" height="85" rx="42.5" strokeWidth="3" />
    <path
      d="M44.5 22C42.9087 22 41.3826 22.6321 40.2574 23.7574C39.1321 24.8826 38.5 26.4087 38.5 28V44C38.5 45.5913 39.1321 47.1174 40.2574 48.2426C41.3826 49.3679 42.9087 50 44.5 50C46.0913 50 47.6174 49.3679 48.7426 48.2426C49.8679 47.1174 50.5 45.5913 50.5 44V28C50.5 26.4087 49.8679 24.8826 48.7426 23.7574C47.6174 22.6321 46.0913 22 44.5 22Z"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M58.5 40V44C58.5 47.713 57.025 51.274 54.3995 53.8995C51.774 56.525 48.213 58 44.5 58C40.787 58 37.226 56.525 34.6005 53.8995C31.975 51.274 30.5 47.713 30.5 44V40"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M44.5 58V66" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M36.5 66H52.5" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default Micro;
