import React from "react";

const Stars = (props: any) => (
  <svg width="177" height="32" viewBox="0 0 177 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M16.242 23.1829C16.4009 23.087 16.5998 23.087 16.7587 23.1829L23.7258 27.388C24.1045 27.6166 24.5716 27.2771 24.4711 26.8463L22.6222 18.9209C22.5801 18.7401 22.6415 18.5509 22.7818 18.4294L28.9397 13.0948C29.2737 12.8054 29.0956 12.2567 28.6553 12.2187L20.5467 11.5195C20.3623 11.5036 20.2018 11.3871 20.1294 11.2167L16.9606 3.75129C16.7882 3.34511 16.2125 3.34511 16.0401 3.75129L12.8712 11.2167C12.7989 11.3871 12.6383 11.5036 12.4539 11.5195L4.34391 12.2188C3.90379 12.2568 3.72551 12.8051 4.05913 13.0946L10.2063 18.4297C10.3462 18.5511 10.4075 18.7398 10.3657 18.9202L8.52747 26.8482C8.42765 27.2787 8.89454 27.6175 9.27292 27.3892L16.242 23.1829Z"
      fill="#31C15E"
    />
    <path
      d="M52.242 23.1829C52.4009 23.087 52.5998 23.087 52.7587 23.1829L59.7258 27.388C60.1045 27.6166 60.5716 27.2771 60.4711 26.8463L58.6222 18.9209C58.5801 18.7401 58.6415 18.5509 58.7818 18.4294L64.9397 13.0948C65.2737 12.8054 65.0956 12.2567 64.6553 12.2187L56.5467 11.5195C56.3623 11.5036 56.2018 11.3871 56.1294 11.2167L52.9606 3.75129C52.7882 3.34511 52.2125 3.34511 52.0401 3.75129L48.8712 11.2167C48.7989 11.3871 48.6383 11.5036 48.4539 11.5195L40.3439 12.2188C39.9038 12.2568 39.7255 12.8051 40.0591 13.0946L46.2063 18.4297C46.3462 18.5511 46.4075 18.7398 46.3657 18.9202L44.5275 26.8482C44.4276 27.2787 44.8945 27.6175 45.2729 27.3892L52.242 23.1829Z"
      fill="#31C15E"
    />
    <path
      d="M88.242 23.1829C88.4009 23.087 88.5998 23.087 88.7587 23.1829L95.7258 27.388C96.1045 27.6166 96.5716 27.2771 96.4711 26.8463L94.6222 18.9209C94.5801 18.7401 94.6415 18.5509 94.7818 18.4294L100.94 13.0948C101.274 12.8054 101.096 12.2567 100.655 12.2187L92.5467 11.5195C92.3623 11.5036 92.2018 11.3871 92.1294 11.2167L88.9606 3.75129C88.7882 3.34511 88.2125 3.34511 88.0401 3.75129L84.8712 11.2167C84.7989 11.3871 84.6383 11.5036 84.4539 11.5195L76.3439 12.2188C75.9038 12.2568 75.7255 12.8051 76.0591 13.0946L82.2063 18.4297C82.3462 18.5511 82.4075 18.7398 82.3657 18.9202L80.5275 26.8482C80.4276 27.2787 80.8945 27.6175 81.2729 27.3892L88.242 23.1829Z"
      fill="#31C15E"
    />
    <path
      d="M124.242 23.1829C124.401 23.087 124.6 23.087 124.759 23.1829L131.726 27.388C132.104 27.6166 132.572 27.2771 132.471 26.8463L130.622 18.9209C130.58 18.7401 130.641 18.5509 130.782 18.4294L136.94 13.0948C137.274 12.8054 137.096 12.2567 136.655 12.2187L128.547 11.5195C128.362 11.5036 128.202 11.3871 128.129 11.2167L124.961 3.75129C124.788 3.34511 124.212 3.34511 124.04 3.75129L120.871 11.2167C120.799 11.3871 120.638 11.5036 120.454 11.5195L112.344 12.2188C111.904 12.2568 111.726 12.8051 112.059 13.0946L118.206 18.4297C118.346 18.5511 118.407 18.7398 118.366 18.9202L116.527 26.8482C116.428 27.2787 116.895 27.6175 117.273 27.3892L124.242 23.1829Z"
      fill="#31C15E"
    />

    <path
      d="M160.242 23.1829C160.401 23.087 160.6 23.087 160.759 23.1829L167.726 27.388C168.104 27.6166 168.572 27.2771 168.471 26.8463L166.622 18.9209C166.58 18.7401 166.641 18.5509 166.782 18.4294L172.94 13.0948C173.274 12.8054 173.096 12.2567 172.655 12.2187L164.547 11.5195C164.362 11.5036 164.202 11.3871 164.129 11.2167L160.961 3.75129C160.788 3.34511 160.212 3.34511 160.04 3.75129L156.871 11.2167C156.799 11.3871 156.638 11.5036 156.454 11.5195L148.344 12.2188C147.904 12.2568 147.726 12.8051 148.059 13.0946L154.206 18.4297C154.346 18.5511 154.407 18.7398 154.366 18.9202L152.527 26.8482C152.428 27.2787 152.895 27.6175 153.273 27.3892L160.242 23.1829Z"
      fill="#31C15E"
    />
  </svg>
);
export default Stars;
