import React from "react";

import CardIcon from "./icons/CardIcon";
import NoteIcon from "./icons/NoteIcon";
import RocketIcon from "./icons/RocketIcon";
import StatsIcon from "./icons/StatsIcon";
import Section from "@/components/Section2";
import { LandingEntity } from "@/domain";

interface CardProps {
  icon: React.ReactNode;
  title: string;
  description: string;
}

const Card: React.FC<CardProps> = ({ title, description, icon }) => (
  <div className="flex w-full flex-col items-center pb-4">
    {icon}
    <h2 className="!mb-1 text-title1 tablet:text-header3 desktop:text-header2">{title}</h2>
    <span className="text-center text-textBodySemi text-secondaryText ">{description}</span>
  </div>
);

const icons = [<CardIcon />, <NoteIcon />, <RocketIcon />, <StatsIcon />];

interface Props {
  features: LandingEntity[];
}

const Features = ({ features }: Props) => (
  <Section data-testid="features-section">
    <div className="grid w-full max-w-xxl grid-cols-2 gap-6 py-10 tablet:gap-8 tablet:py-12 desktop:grid-cols-4 desktop:py-15">
      {features.map((entity: LandingEntity, index: number) => (
        <Card key={entity.id} title={entity.title} description={entity.text} icon={icons[index]} />
      ))}
    </div>
  </Section>
);

export default React.memo(Features);
