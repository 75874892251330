import React from "react";
import { LandingNewsEntity } from "@/domain";
import { useRouter } from "next/router";

interface Props {
  data: LandingNewsEntity;
  brief?: boolean;
}

const News: React.FC<Props> = ({ data }) => {
  const router = useRouter();
  return (
    <>
      <section
        className="flex w-full flex-col items-start gap-5 desktop:gap-7"
        role="button"
        onClick={() => {
          router.push(`/news/${data.id}`, undefined, {
            shallow: true,
          });
        }}
      >
        {/* Picture */}
        {data.image && (
          <img src={data.image} className="aspect-3/2 w-full rounded-2xl object-cover" alt={data.createdAt} />
        )}
        {/* Title */}
        <div className="flex flex-col gap-2 desktop:gap-4">
          <h3 className="text-extraSmallTitle text-primaryText desktop:text-title1">{data.title}</h3>
          {/* Date */}
          <h3 className="text-textBody text-label opacity-70 tablet:text-textSmall desktop:text-textMedium">
            {new Date(data.createdAt).toLocaleDateString("ru-RU")}
          </h3>
        </div>
      </section>
    </>
  );
};

export default React.memo(News);
