const StatsIcon = () => (
  <svg width="137" height="136" viewBox="0 0 137 136" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="24.75" y="24" width="88" height="88" rx="44" fill="#2196F3" />
    <path
      d="M60.9788 59.2002H77.0205C77.6622 59.2002 78.1663 58.6839 78.1663 58.0268V54.5068C78.1663 53.8498 77.6622 53.3335 77.0205 53.3335H60.9788C60.3372 53.3335 59.833 53.8498 59.833 54.5068V58.0268C59.833 58.6839 60.3372 59.2002 60.9788 59.2002Z"
      fill="white"
    />
    <path
      d="M78.1663 63.8935V66.2402C78.1663 66.8972 77.6622 67.4135 77.0205 67.4135H74.7288C74.0872 67.4135 73.583 66.8972 73.583 66.2402V63.8935C73.583 63.2364 74.0872 62.7202 74.7288 62.7202H77.0205C77.6622 62.7202 78.1663 63.2364 78.1663 63.8935Z"
      fill="white"
    />
    <path
      d="M64.4163 63.8935V66.2402C64.4163 66.8972 63.9122 67.4135 63.2705 67.4135H60.9788C60.3372 67.4135 59.833 66.8972 59.833 66.2402V63.8935C59.833 63.2364 60.3372 62.7202 60.9788 62.7202H63.2705C63.9122 62.7202 64.4163 63.2364 64.4163 63.8935Z"
      fill="white"
    />
    <path
      d="M71.2913 63.8935V66.2402C71.2913 66.8972 70.7872 67.4135 70.1455 67.4135H67.8538C67.2122 67.4135 66.708 66.8972 66.708 66.2402V63.8935C66.708 63.2364 67.2122 62.7202 67.8538 62.7202H70.1455C70.7872 62.7202 71.2913 63.2364 71.2913 63.8935Z"
      fill="white"
    />
    <path
      d="M78.1663 71.5202V73.8668C78.1663 74.5239 77.6622 75.0402 77.0205 75.0402H74.7288C74.0872 75.0402 73.583 74.5239 73.583 73.8668V71.5202C73.583 70.8631 74.0872 70.3468 74.7288 70.3468H77.0205C77.6622 70.3468 78.1663 70.8631 78.1663 71.5202Z"
      fill="white"
    />
    <path
      d="M64.4163 71.5202V73.8668C64.4163 74.5239 63.9122 75.0402 63.2705 75.0402H60.9788C60.3372 75.0402 59.833 74.5239 59.833 73.8668V71.5202C59.833 70.8631 60.3372 70.3468 60.9788 70.3468H63.2705C63.9122 70.3468 64.4163 70.8631 64.4163 71.5202Z"
      fill="white"
    />
    <path
      d="M71.2913 71.5202V73.8668C71.2913 74.5239 70.7872 75.0402 70.1455 75.0402H67.8538C67.2122 75.0402 66.708 74.5239 66.708 73.8668V71.5202C66.708 70.8631 67.2122 70.3468 67.8538 70.3468H70.1455C70.7872 70.3468 71.2913 70.8631 71.2913 71.5202Z"
      fill="white"
    />
    <path
      d="M78.1663 79.1468V81.4935C78.1663 82.1506 77.6622 82.6668 77.0205 82.6668H74.7288C74.0872 82.6668 73.583 82.1506 73.583 81.4935V79.1468C73.583 78.4898 74.0872 77.9735 74.7288 77.9735H77.0205C77.6622 77.9735 78.1663 78.4898 78.1663 79.1468Z"
      fill="white"
    />
    <path
      d="M71.2913 79.1468V81.4935C71.2913 82.1506 70.7872 82.6668 70.1455 82.6668H60.9788C60.3372 82.6668 59.833 82.1506 59.833 81.4935V79.1468C59.833 78.4898 60.3372 77.9735 60.9788 77.9735H70.1455C70.7872 77.9735 71.2913 78.4898 71.2913 79.1468Z"
      fill="white"
    />
    <rect
      x="54"
      y="88.5"
      width="41"
      height="30"
      rx="4.5"
      transform="rotate(-90 54 88.5)"
      stroke="white"
      strokeWidth="3"
    />
  </svg>
);

export default StatsIcon;
