const PlainArrow = () => (
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="stroke-primary"
  >
    <path
      d="M7.16699 16.0003H25.8337M25.8337 16.0003L16.5003 6.66699M25.8337 16.0003L16.5003 25.3337"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default PlainArrow;
