import clsx from "clsx";

type Props = {
  className?: string;
};

const LandingSectionTitle: React.FC<Props> = ({ children, className }) => {
  return <h3 className={clsx(" text-title1 tablet:text-header3 desktop:text-header2", className)}>{children}</h3>;
};

export default LandingSectionTitle;
