import React from "react";
import clsx from "clsx";

import Button from "@/components/Button2";
import NoSSR from "@/components/NoSSR";

import useUser from "@/data/hooks/useUser";
import { LandingEntity } from "@/domain";
import { useRouter } from "next/router";

interface Props {
  info: LandingEntity;
}

const Hero: React.FC<Props> = ({ info }) => {
  const { authenticated } = useUser();
  const router = useRouter();

  return (
    <div
      data-testid="hero-section"
      className={clsx(
        "flex flex-col items-center justify-start",
        "bg-contain bg-bottom bg-no-repeat tablet:h-[65vw] tablet:max-h-[620px] tablet:bg-hero-tablet heroTablet:bg-cover desktop:h-[55vw] desktop:max-h-[1100px] desktop:bg-hero-desktop desktop:bg-cover at1500:h-[52vw] at1500:max-h-[950px] at1920:h-[49vw] at1920:max-h-[1200px] at1920:min-h-[980px]",
        "w-full gap-4 px-6 pt-8 tablet:gap-6 tablet:px-8 tablet:pb-[213px] tablet:pt-15 desktop:gap-8 desktop:px-[270px] desktop:pb-[490px] desktop:pt-16",
      )}
    >
      <h1 className="text-center text-header3 tablet:text-header2 desktop:text-header1">{info.title}</h1>
      <div
        className="w-full text-center text-textBody opacity-80 tablet:w-2/3 tablet:text-textMedium desktop:w-full"
        dangerouslySetInnerHTML={{ __html: info.text }}
      ></div>

      <NoSSR>
        <Button
          className="!w-fit"
          type="button"
          onClick={
            authenticated ? () => router.push("/dashboard/orders") : () => router.push("/auth/register/create-account")
          }
        >
          <span className="flex items-center justify-center">
            {authenticated ? "Создать заказ" : "Зарегистрироваться"}
          </span>
        </Button>
      </NoSSR>
    </div>
  );
};

export default React.memo(Hero);
