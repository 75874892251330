import React, { MouseEventHandler, useCallback } from "react";
import clsx from "clsx";
import { LandingEntity } from "@/domain";
import Plus from "@/components/icons/math/Plus";
import Minus from "@/components/icons/math/Minus";

interface Props {
  data: LandingEntity;
  open: boolean;
  onToggle: (id: number) => void;
}

const FaqItem: React.FC<Props> = ({ data, open, onToggle }) => {
  const handleToggle: MouseEventHandler<HTMLDetailsElement> = useCallback(
    (event) => {
      event.stopPropagation();
      event.preventDefault();
      onToggle(data.id);
    },
    [onToggle, data.id],
  );

  return (
    <details className={clsx("w-full border-t border-divider py-6 last:border-b")} open={open} onClick={handleToggle}>
      <summary className="flex cursor-pointer flex-row items-center gap-4">
        {open ? <Minus /> : <Plus />}

        <span className="text-textLargeSemi">{data.title}</span>
      </summary>
      <div
        id="faq-item"
        className={clsx("pl-14 pt-5", "prose-sm", "faq-item")}
        dangerouslySetInnerHTML={{ __html: data.text }}
      ></div>
    </details>
  );
};

export default React.memo(FaqItem);
