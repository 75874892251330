import React, { HTMLAttributes } from "react";
import clsx from "clsx";
import { LandingEntity } from "@/domain";
import { RoleName } from "@/data/constants";
import PlainArrow from "@/components/icons/Arrow/PlainArrow";

export type Step = LandingEntity;

export interface Props {
  step: Step;
  order: number;
  isAuthorized: boolean;
  role?: RoleName;
}

const Step: React.FC<Props & HTMLAttributes<HTMLDivElement>> = React.memo(
  ({ order, step, isAuthorized, role, className }) => {
    return (
      <div
        className={clsx("flex flex-shrink flex-col justify-center tablet:flex-row", {
          "tablet:w-[30.25%] desktop:w-[354px]": order !== 2,
          "tablet:w-[39.5%] desktop:w-fit": order === 2,
        })}
      >
        {order === 2 && (
          <div className="text-primary desktop:min-h-[212px] desktop:px-8">
            <div className="flex h-1/2 flex-row items-center justify-center pb-3 pt-3 tablet:pb-0 tablet:pt-0">
              <span className="rotate-90 tablet:rotate-0">
                <PlainArrow />
              </span>
            </div>
          </div>
        )}
        <div className="flex flex-col items-center tablet:min-h-[212px]">
          <div className="flex h-28 flex-row items-center justify-center">
            <div className="flex h-16 w-16 items-center justify-center rounded-full border-[3px] border-primary text-header2semi text-primary">
              {order}
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <h2 className="text-center text-textMediumSemi tablet:text-textBodySemi desktop:text-textMediumSemi">
              {/* <StepTitle step={step} order={order} isAuthorized={isAuthorized} role={role} /> */}
              {step.title}
            </h2>
            <p className="pb-4 text-center text-textBody text-label tablet:pb-0 tablet:text-textSmall desktop:text-textBody">
              {step.text}
            </p>
          </div>
        </div>
        {order === 2 && (
          <div className="text-primary desktop:min-h-[212px] desktop:px-8">
            <div className="flex h-1/2 flex-row items-center justify-center pb-3 pt-3 tablet:pb-0 tablet:pt-0">
              <span className="rotate-90 tablet:rotate-0">
                <PlainArrow />
              </span>
            </div>
          </div>
        )}
      </div>
    );
  },
);

export default React.memo(Step);
