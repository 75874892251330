const CardIcon = () => (
  <svg width="137" height="136" viewBox="0 0 137 136" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="24.25" y="24" width="88" height="88" rx="44" fill="#2196F3" />
    <rect x="45.5" y="53.8999" width="45" height="28.2" rx="4.5" stroke="white" strokeWidth="3" />
    <rect x="51.2002" y="59.6001" width="8" height="6" rx="1" fill="white" />
    <rect x="78.7998" y="59.6001" width="6" height="6" rx="3" fill="white" />
    <rect x="75.2002" y="59.6001" width="6" height="6" rx="3" fill="white" />
    <rect x="51.2002" y="72.7998" width="6.6" height="3.6" rx="1.8" fill="white" />
    <rect x="60.2002" y="72.7998" width="6.6" height="3.6" rx="1.8" fill="white" />
    <rect x="69.2002" y="72.7998" width="6.6" height="3.6" rx="1.8" fill="white" />
    <rect x="78.2002" y="72.7998" width="6.6" height="3.6" rx="1.8" fill="white" />
  </svg>
);

export default CardIcon;
