import React from "react";

import HowToUseStep, { Step } from "./Step";
import useUser from "@/data/hooks/useUser";
import { roles } from "@/data/constants";
import Section from "@/components/Section2";
import Button from "@/components/Button2/Button";
import { useRouter } from "next/router";

interface Props {
  steps: Step[];
}

const HowToGetStarted: React.FC<Props> = ({ steps }) => {
  const { user } = useUser();
  const router = useRouter();

  return (
    <Section data-testid="howtogetstarted-section">
      <div className="flex w-full max-w-xxl flex-col items-center justify-center gap-5 py-10 tablet:gap-8 tablet:py-12 desktop:gap-12 desktop:py-15">
        <h2 className="text-title1 tablet:text-header2">Начните с нами работать</h2>

        <div className="flex flex-col tablet:mb-4 tablet:flex-row desktop:mb-2">
          {steps.map((step, index) => (
            <HowToUseStep
              key={step.id}
              order={index + 1}
              step={step}
              isAuthorized={!!user}
              role={user?.isMover ? roles.MOVER : roles.CUSTOMER}
            />
          ))}
        </div>

        <Button
          size="large"
          onClick={() => (!!user ? router.push("/dashboard/orders") : router.push("/auth/register/create-account"))}
          className="!w-fit"
        >
          {!!user ? "Создать заказ" : "Зарегистрироваться"}
        </Button>
      </div>
    </Section>
  );
};

export default React.memo(HowToGetStarted);
